import './AreYouSure.scss';
import React, { useRef, useState } from 'react';
import Button from './inputs/Button';
import useTokenStorage from '../hooks/useTokenStorage';
import { useNavigate } from 'react-router';

const AreYouSure = ({ url, children, isOpen, setIsOpen, verySure, successCallback, noButtons, deleteUrl }) => {

   const verySureRef = useRef(null);
   const [IAmSureError, setIAmSureError] = useState(null);
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();

   const [getTokens, saveToken, removeToken, clearTokens, getToken, getTokenExpriy, refreshToken] = useTokenStorage();
   
   function redirectToLogin() {
       const queryPath = window.location.pathname;
       navigate('/login?redirect=' + encodeURIComponent(queryPath), { replace: true });
       clearTokens();
       setLoading(false);
   }
   
   const handleConfirm = async () => {

      if (verySure && verySureRef.current.value !== "i-am-sure") {
         setIAmSureError("If you wish to confirm, please type the confirmation value.")
         return;
      }

      setLoading(true);

      const action = deleteUrl ? "delete" : "post";
      const requestOptions = {
         method: action,
         headers: {},
         body: {}
      }
   
      const access_token = getToken('access_token');
      const access_token_expiry = getTokenExpriy('access_token');
      const refresh_token_expiry = getTokenExpriy('refresh_token');
   
      if (access_token && access_token_expiry && refresh_token_expiry) {
         if (Date.now() > access_token_expiry && Date.now() <= refresh_token_expiry) {
            refreshToken()
                  .then(() => {
                     continueWithFetch();
                  })
                  .catch(() => {});
         } else if (Date.now() + 5 > refresh_token_expiry && window.location.pathname != "/login") {
            redirectToLogin();
         } else {
            continueWithFetch();
         }
      } else {
         continueWithFetch();
      }

      function continueWithFetch() {
         const new_access_token = getToken('access_token');
  
         if (new_access_token) requestOptions.headers['Authorization'] = 'Bearer ' + new_access_token;
  
         fetch(url, requestOptions).then(() => {
  
         }).catch(() => {}).finally(() => {
            setIsOpen(false)
            setLoading(false);
            if (successCallback) successCallback();
         });
     }

   };


   const handleCancel = () => {
      setIsOpen(false);
   };

   return (
      <div 
         id="are-you-sure-wrapper"
         className={isOpen ? " open" : ""}
         >
         <div
            id="are-you-sure"
         >
            <div>{children}</div>

            {!noButtons &&
               <div className="buttons">
                  <Button name="Annuleren" onClick={handleCancel} cta noLink customIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>} />
                  <Button name="Bevestigen" loading={loading} onClick={handleConfirm} noLink customIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>} />
               </div>
            }

         </div>
      </div>
   );
};

export default AreYouSure;