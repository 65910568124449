import Cookies from 'universal-cookie';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { ReactComponent as HorizontalLogo } from '../assets/ybw_logo.svg';
import { ReactComponent as Logo } from '../assets/ybw_logo.svg';
import useFetch from '../hooks/useFetch';

import './Nav.scss';

function NavList(props) {

   const [showLists, setShowLists] = useState([]);

   const show = (e) => {
      e.currentTarget.classList.add('show');
   }

   const hide = (e) => {
      e.currentTarget.classList.remove('show');
   }

   useEffect(() => {

      if (!Array.isArray(props.items)) {
         setShowLists(Array(Object.keys(props.items).length).fill(false));
      }

   }, [props]);

   return (
      <div 
         className="nav-list" 
         id={props.id} 
         onMouseOver={show} 
         onMouseOut={hide}>

         <Link 
            to={props.href}
            onClick={props.onClick}
            >
            {props.title &&
               <span>{props.title}</span>
            }
         </Link>

         <div
            className={`length-${showLists.length}`}
            onClick={hide}
            >

            {Array.isArray(props.items) &&

               <ul>
                  {props.items.map((value, key) => {
                     return (
                        <Link key={key} to={value.href} onClick={props.onClick}>
                           <li>{value.icon}{value.name}</li>
                        </Link>
                     );
                  })}
               </ul>
            }

            {!Array.isArray(props.items) && Object.keys(props.items).map((category, key) => {

               return (

                  <ul
                     className={(showLists && showLists[key] === true) ? "show" : ""}
                     >

                     <label 
                        onClick={() => {
                           console.log('click');
                           const newShowLists = [...showLists];
                           newShowLists[key] = !newShowLists[key];
                           setShowLists(newShowLists);
                        }}
                        >
                           {category}
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/></svg>
                     </label>


                     {props.items[category].map((value, key) => {
                        return (
                           <Link key={key} to={value.href} onClick={props.onClick}>
                              <li>{value.icon}{value.name}</li>
                           </Link>
                        );
                     })}
                  </ul>

               );

            })}

      </div>

      </div>
      
   )

}

export default function Nav() {

   const [showNav, setShowNav] = useState(false);
   const [desktopShowNav, setDesktopShowNav] = useState(true);

   const [activityMap, setActivityMap] = useState({});
   const [activityLinks, setActivityLinks] = useState([]);
   const [locationLinks, setLocationLinks] = useState([]);
   const [atmosphereLinks, setAtmosphereLinks] = useState([]);

   const [showProfile, setShowProfile] = useState(false);

   const { user } = useContext(AuthContext);

   const cookies = new Cookies();

   const navigate = useNavigate();
   const location = useLocation();

   const request = {
      activities: {
          endpoint: "/api/v1/activities",
          method: "GET",
      },
      options: {
         endpoint: "/api/v1/activities/options",
         method: "GET",
     },
     cities: {
         endpoint: "/api/v1/activities/cities",
         method: "GET"
     }
   }

   const [data, loading, error] = useFetch(request);

   useEffect(() => {
      if (data.activities && data.options && data.cities) {
         setInitLinks(data.activities.data, data.options.data, data.cities.data);
      }
   }, [loading]);

   const setInitLinks = (activities, options, cities) => {

      const newActivityLinks = {};
      for (const activity of options) {
         
         if (!(activity.category in newActivityLinks)) {
            newActivityLinks[activity.category] = [];
         } else if (newActivityLinks[activity.category].length >= 12) {
            continue;
         }

         newActivityLinks[activity.category].push({
            name: activity.pretty_name.charAt(0).toUpperCase() + activity.pretty_name.substring(1),
            href: '/activities?options[]=' + activity.id
         })

      }      
      setActivityLinks(newActivityLinks);

      const newLocationLinks = {};
      for (const city of cities) {

         if (!(city.province in newLocationLinks)) {
            newLocationLinks[city.province] = [];
         } else if (newLocationLinks[city.province].length >= 12) {
            continue;
         }

         newLocationLinks[city.province].push(
            {name: city.name, href: "/activities?city=" + city.id}
         );

      }      
      setLocationLinks(newLocationLinks);

   }

   const toggleNav = () => {
      setShowNav(!showNav);
   }

   useEffect(() => {
      setShowProfile(false);
   }, []);

   const hideLinks = ["/login", "/welcome", "/calculator", "/reset-password", "/request-password", "/signup"].includes(location.pathname.replace(/\/$/, ''));
   const queryPath = window.location.pathname;

   return (
      <nav>

         <div id="nav-bar"
            className={desktopShowNav ? "show-bar" : ""}>
            
            <div id="nav-bar-elements" className={hideLinks ? "no-links" : ""}>

               <div id="nav-logo">

                  <Link to="/">
                     <Logo />
                  </Link>

               </div>

               {!hideLinks &&

                  <div id="nav-bar-links">
                        
                     <NavList
                        title="Activiteiten"
                        onClick={toggleNav}
                        items={activityLinks}
                        href="/activities"
                        />

                     <NavList
                        title="Locaties"
                        onClick={toggleNav}
                        items={locationLinks} />
                     
                     <div id="profile-link"
                        onMouseOver={() => {setShowProfile(true)}}
                        onMouseOut={() => {setShowProfile(false)}}
                        onClick={() => setShowProfile(false)}
                        >

                        <div
                           id="profile-navbar"
                           >

                        <span>{user && user.type ? (user.first_name ? user.first_name : "Nieuweling") : 'Inloggen'}</span>
                        <svg 
                           onClick={() => navigate('/hub')}
                           xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.822 18.096c-3.439-.794-6.641-1.49-5.09-4.418 4.719-8.912 1.251-13.678-3.732-13.678-5.082 0-8.465 4.949-3.732 13.678 1.598 2.945-1.725 3.641-5.09 4.418-2.979.688-3.178 2.143-3.178 4.663l.005 1.241h1.995c0-3.134-.125-3.55 1.838-4.003 2.851-.657 5.543-1.278 6.525-3.456.359-.795.592-2.103-.338-3.815-2.058-3.799-2.578-7.089-1.423-9.026 1.354-2.275 5.426-2.264 6.767-.034 1.15 1.911.639 5.219-1.403 9.076-.91 1.719-.671 3.023-.31 3.814.99 2.167 3.707 2.794 6.584 3.458 1.879.436 1.76.882 1.76 3.986h1.995l.005-1.241c0-2.52-.199-3.975-3.178-4.663z"/></svg>

                        </div>

                        <div id="profile-info"
                           className={showProfile ? "show" : ""}>
                           {(user && user.type) &&
                              <h4>Welkom, {user.first_name ? user.first_name : "nieuweling"}.</h4>}
                           {(user && user.num_tokens !== undefined) && 
                           <>
                              <div 
                                 id="profile-tokens"
                                 onClick={() => { navigate('/hub/profile') }}
                                 >
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.728 20.517c-3.488 0-5.613-2.461-6.443-5.517h6.715l.333-2h-7.398c-.059-.664-.064-1.335-.014-2h7.746l.333-2h-7.755c.786-3.106 2.855-5.626 6.154-5.626 1.133 0 2.391.203 3.836.62l.765-3.162c-1.854-.552-3.616-.832-5.244-.832-5.959 0-9.541 4.152-10.594 9h-2.162l-.333 2h2.203c-.049.666-.051 1.334-.007 2h-2.53l-.333 2h3.145c1.033 4.848 4.664 9 11.085 9 1.5 0 3.004-.276 4.476-.821l-.883-3.23c-1.048.378-2.088.568-3.095.568z"/></svg>
                                 {user.num_tokens ? user.num_tokens : 0}
                              </div>
                              <div 
                                 id="profile-bits"
                                 onClick={() => { navigate('/hub/wellbits') }}
                                 >
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 1c-.438 1.438-1.563 2.562-3 3 1.437.438 2.562 1.563 3 3 .438-1.437 1.563-2.561 3-3-1.437-.438-2.562-1.562-3-3zm-19.001 2c-.292.957-1.042 1.708-1.999 2 .959.293 1.707 1.042 2.001 2 .292-.958 1.042-1.708 1.999-2-.957-.292-1.707-1.042-2.001-2zm18.5 16c-.365 1.196-1.303 2.134-2.499 2.5 1.199.366 2.134 1.303 2.501 2.5.365-1.197 1.303-2.134 2.499-2.5-1.196-.364-2.134-1.303-2.501-2.5zm-9.499-19c-.292.958-1.042 1.708-2 2 .959.293 1.708 1.042 2.002 2 .292-.958 1.042-1.708 1.998-2-.956-.292-1.706-1.042-2-2zm-8.001 18c-.435 1.436-1.563 2.561-2.999 3 1.439.439 2.564 1.564 3 3 .439-1.436 1.564-2.561 2.999-3-1.435-.437-2.56-1.564-3-3zm14.063-12h-10.054l-5.008 5.625 10 12.375 10-12.305-4.938-5.695zm-9.394 6l3.039 7.218-5.832-7.218h2.793zm7.579 0l-3.247 7.711-3.247-7.711h6.494zm-6.04-1l2.793-2.793 2.793 2.793h-5.586zm7.125 1h2.843l-5.899 7.259 3.056-7.259zm2.418-1h-2.543l-3-3h2.942l2.601 3zm-10.846-3h2.889l-3 3h-2.559l2.67-3z"/></svg>
                                 {user.num_bits ? user.num_bits : 0}
                              </div>
                           </>   
                           }

                           {(!user || !user.type) &&
                              <>
                                 <Link 
                                    id="profile-link-signup"
                                    to={'/signup'}>Aanmelden</Link>
                                 <Link 
                                    id="profile-link-login"
                                    to={`/login?redirect=${encodeURIComponent(queryPath)}`}>Inloggen</Link>
                              </>
                           }

                           <Link
                              to="/hub/profile">Mijn profiel</Link>
                           <Link
                              to="/hub/chats">Mijn boekingen</Link>

                           {(user && user.type) &&
                              <Link
                                 id="profile-logout"
                                 to="/logout">Uitloggen</Link>
                           }
                              
                        </div>

                     </div>
   
                  </div>

               }

            </div>

            <div id="nav-window-mobile"
               className={showNav ? "shown" : ""}>

               <div id="nav-window-mobile-close"
                  onClick={toggleNav}></div>

               <div id="nav-window-mobile-buttons"
                  onClick={toggleNav}>

                  <Link>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>
                     <span>Terug</span>
                  </Link>

               </div>


               {(user && user.num_tokens !== null && user.num_tokens !== undefined) && 
                  <>
                     <div 
                        id="nav-window-tokens-mobile"
                        onClick={() => { navigate('/hub/profile') }}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.728 20.517c-3.488 0-5.613-2.461-6.443-5.517h6.715l.333-2h-7.398c-.059-.664-.064-1.335-.014-2h7.746l.333-2h-7.755c.786-3.106 2.855-5.626 6.154-5.626 1.133 0 2.391.203 3.836.62l.765-3.162c-1.854-.552-3.616-.832-5.244-.832-5.959 0-9.541 4.152-10.594 9h-2.162l-.333 2h2.203c-.049.666-.051 1.334-.007 2h-2.53l-.333 2h3.145c1.033 4.848 4.664 9 11.085 9 1.5 0 3.004-.276 4.476-.821l-.883-3.23c-1.048.378-2.088.568-3.095.568z"/></svg>
                        {user.num_tokens ? user.num_tokens : 0}
                     </div>
                     <div 
                        id="nav-window-bits-mobile"
                        onClick={() => { navigate('/hub/wellbits') }}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 1c-.438 1.438-1.563 2.562-3 3 1.437.438 2.562 1.563 3 3 .438-1.437 1.563-2.561 3-3-1.437-.438-2.562-1.562-3-3zm-19.001 2c-.292.957-1.042 1.708-1.999 2 .959.293 1.707 1.042 2.001 2 .292-.958 1.042-1.708 1.999-2-.957-.292-1.707-1.042-2.001-2zm18.5 16c-.365 1.196-1.303 2.134-2.499 2.5 1.199.366 2.134 1.303 2.501 2.5.365-1.197 1.303-2.134 2.499-2.5-1.196-.364-2.134-1.303-2.501-2.5zm-9.499-19c-.292.958-1.042 1.708-2 2 .959.293 1.708 1.042 2.002 2 .292-.958 1.042-1.708 1.998-2-.956-.292-1.706-1.042-2-2zm-8.001 18c-.435 1.436-1.563 2.561-2.999 3 1.439.439 2.564 1.564 3 3 .439-1.436 1.564-2.561 2.999-3-1.435-.437-2.56-1.564-3-3zm14.063-12h-10.054l-5.008 5.625 10 12.375 10-12.305-4.938-5.695zm-9.394 6l3.039 7.218-5.832-7.218h2.793zm7.579 0l-3.247 7.711-3.247-7.711h6.494zm-6.04-1l2.793-2.793 2.793 2.793h-5.586zm7.125 1h2.843l-5.899 7.259 3.056-7.259zm2.418-1h-2.543l-3-3h2.942l2.601 3zm-10.846-3h2.889l-3 3h-2.559l2.67-3z"/></svg>
                        {user.num_bits ? user.num_bits : 0}
                     </div>
                  </>
               }

               <NavList
                  title="Profiel"
                  onClick={toggleNav}
                  items={user && user.num_tokens !== null && user.num_tokens !== undefined ? 
                     [
                        {name: 'Mijn profiel', href: '/hub/profile'},
                        {name: 'Mijn boekingen', href: '/hub/chats'}
                     ] :
                     [
                        {name: 'Aanmelden', href: '/signup'},
                        {name: 'Inloggen', href: '/login'},
                     ]
                  }
                  href="/hub"
                  />

               <NavList
                  title="Activiteiten"
                  onClick={toggleNav}
                  items={activityLinks}
                  href="/activities"
                  />

               <NavList
                  title="Locaties"
                  onClick={toggleNav}
                  items={locationLinks} />

               {(user && user.type) &&

                  <NavList 
                     id="nav-list-logout"
                     onClick={toggleNav}
                     items={[{name: 'Uitloggen', href: '/logout'}]}
                     />

               }

               <div id="nav-window-mobile-footer">

                  <Link to="/" onClick={toggleNav}>
                     <HorizontalLogo />
                  </Link>

               </div>

            </div>
            
            {!hideLinks &&
               <svg 
                  id="nav-window-mobile-show"
                  onClick={toggleNav} 
                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"/></svg>
            }

         </div>

      </nav>
   )

}