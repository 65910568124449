import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useFetch from '../hooks/useFetch';

import { ReactComponent as EditorIcon } from '../assets/faq_editor.svg';
import { ReactComponent as EmployerIcon } from '../assets/faq_employer.svg';
import { ReactComponent as UserIcon } from '../assets/faq_user.svg';

import './DocsPage.scss';

function QuestionAndAnswer (props) {

   const [shown, setShown] = useState(false);

   useEffect(() => {
      setShown(false);
   }, [props.question]);

   const formatAnswer = (text) => {

      const lines = text.split('\n');
    
      let inList = false;
      let currentHTML = '';
      let consecutiveEmptyLines = 0;

      lines.forEach((line, index) => {

         const numberedListMatch = line.match(/^\s*\d+\.\s(.+)/);
         const bulletMatch = line.match(/^\s*•\s(.+)/);
   
         if (numberedListMatch || bulletMatch) {
            // Add any pending empty lines before starting a new list
            if (!inList && consecutiveEmptyLines > 0) {
              currentHTML += '<br />'.repeat(consecutiveEmptyLines);
              consecutiveEmptyLines = 0;
            }
            
            const content = (numberedListMatch || bulletMatch)[1];
            if (!inList) {
              inList = true;
              currentHTML += `<ul><li>${content}</li>`;
            } else {
              currentHTML += `<li>${content}</li>`;
            }
          } else if (inList) {
            inList = false;
            currentHTML += '</ul>';
            // Directly append content without break if it exists
            if (line.trim()) {
              currentHTML += line;
              consecutiveEmptyLines = 0;
            } else {
              consecutiveEmptyLines = 1;
            }
          } else {
            if (line.trim()) {
              if (consecutiveEmptyLines > 0) {
                currentHTML += '<br />'.repeat(consecutiveEmptyLines);
                consecutiveEmptyLines = 0;
              }
              currentHTML += currentHTML ? '<br />' + line : line;
            } else {
              consecutiveEmptyLines++;
            }
          }
      });
  
      if (inList) {
        currentHTML += '</ul>';
      }
  
      return currentHTML;

   }

   return (

      <div className={"question-and-answer" + (shown ? " show": "")}>

         <h4 
            onClick={() => { setShown(!shown); }}>
               {props.question}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/></svg>
         </h4>
         <p dangerouslySetInnerHTML={{__html: formatAnswer(props.answer)}} ></p>

      </div>

   );

}

function FAQ (props) {

   const [category, setCategory] = useState("user");
   const [shownQuestionsAndAnswers, setShownQuestionsAndAnswers] = useState([]);

   useEffect(() => {

      if (props.questionsAndAnswers && Object.keys(props.questionsAndAnswers).length && category) {
         const newQuestionsAndAnswers = props.questionsAndAnswers[category];
         setShownQuestionsAndAnswers(newQuestionsAndAnswers);
      }

   }, [props.questionsAndAnswers, category]);

   return (

      <>
      
         <div id="faq-switcher">

            <div 
               className={"switch" + (category == "user" ? " active" : "")}
               onClick={() => {
                  setCategory("user")
               }}
            >
               <UserIcon />
               <h3>Gebruiker</h3>
            </div>

            <div 
               className={"switch" + (category == "editor" ? " active" : "")}
               onClick={() => {
                  setCategory("editor")
               }}
            >
               <EditorIcon />
               <h3>Aanbieder</h3>
            </div>

            <div 
               className={"switch" + (category == "employer" ? " active" : "")}
               onClick={() => {
                  setCategory("employer")
               }}
               >
               <EmployerIcon />
               <h3>Werkgever</h3>
            </div>

         </div>

         <div id="questions-and-answers">

            {shownQuestionsAndAnswers.map((value, key) => {

               return (
                  <QuestionAndAnswer
                     key={key + category}
                     question={value.question}
                     answer={value.answer}
                      />
               )

            })}

         </div>
      
      </>

   );

}


export default function InsightsOverviewPage() {
   
   const request = {
      activities: {
          endpoint: "/api/v1/activities?n=3",
          method: "GET",
      },
      activitiesForFilters: {
          endpoint: "/api/v1/activities",
          method: "GET",
      }
   }
   
   const navigate = useNavigate();

   // FAQ
   const [faq, setFaq] = useState({});
   useEffect(() => {

      const faqJSON = require("../assets/faq.json");
      setFaq(faqJSON);

   }, []);

   const { path } = useParams();


   return (

      <main id="learn-overview">

         <section id="hero">

            <div className="container">
               <h2>You Be Well Academy<span className="stress">.</span></h2>
               <p>Lees hier over de werking van ons platform.</p>
            </div>

         </section>

         <section id="learn-container" className="container"> 

            <div id="learn-content">
               
               {path === 'faq' &&
                  <section id="learn-faq">
                     <h2 id="learn-anchor-faq">FAQ.</h2>
                     <p>Vind je antwoord in onze veelgestelde vragen.</p>

                     <FAQ 
                        questionsAndAnswers={faq}
                        />

                  </section>
               }

               {path === 'privacy' &&

                  <section id="learn-privacy">

                     <h2>PRIVACYVERKLARING</h2>

                     <p>You Be Well respecteert de privacy van alle gebruikers van haar platform, waaronder ook leveranciers en aanbieders (verder aangeduid als 'Gebruikers'), en zet zich in om hun rechten te beschermen, met name met betrekking tot de geautomatiseerde verwerking van persoonsgegevens. In het kader van volledige transparantie hebben wij beleid geformuleerd en geïmplementeerd over het gebruik van persoonsgegevens, het doel van deze verwerking en de mogelijkheden voor betrokkenen om hun rechten optimaal te kunnen uitoefenen.</p>

                     <p>Voor aanvullende informatie over de bescherming van persoonsgegevens kunt u terecht op de website van de <Link to="https://autoriteitpersoonsgegevens.nl" target="_blank">Autoriteit Persoonsgegevens</Link>. De privacyverklaring die aan u beschikbaar wordt gesteld, is de enige versie die van toepassing is totdat een nieuwe versie de huidige vervangt.</p>

                     <h3>Artikel 1 - Wettelijke bepalingen</h3>
                     <p>Verantwoordelijke voor de verwerking van persoonsgegevens (hierna ook: "de Beheerder"): You Be Well B.V., gevestigd te Prof. Snijdersstraat 8, 2628RA Delft, Kvk-nummer: 77425812.</p>

                     <h3>Artikel 2 - De verwerking van persoonsgegevens</h3>
                     <p>Uw persoonsgegevens worden verzameld en verwerkt door You Be Well in overeenstemming met de Algemene Verordening Gegevensbescherming (AVG). Onder persoonsgegevens wordt verstaan: alle informatie over een geïdentificeerde of identificeerbare natuurlijke persoon. Een persoon wordt als identificeerbaar beschouwd wanneer deze direct of indirect kan worden geïdentificeerd, bijvoorbeeld aan de hand van een naam, identificatienummer, locatiegegevens, online identificator of andere kenmerken die kenmerkend zijn voor zijn/haar identiteit.</p>

                     <p>Wij verwerken de volgende categorieën persoonsgegevens van u:</p>
                     <ul>
                        <li>Contactgegevens: naam, voornamen, voorletters, geslacht, geboortedatum</li>
                        <li>Adresgegevens</li>
                        <li>E-mailadres(sen)</li>
                        <li>Telefoonnummer</li>
                        <li>Bank- en financiële gegevens</li>
                        <li>Gegevens betreffende werk: zoals, maar niet beperkt tot, functie</li>
                        <li>Bedrijfsgegevens: zoals (maar niet beperkt tot) bedrijfsnaam, omschrijving, openingstijden, en details van aangeboden diensten en activiteiten op het platform</li>
                        <li>Beeldmateriaal</li>
                        <li>Inloggegevens en gebruikersprofielgegevens</li>
                     </ul>

                     <h3>Artikel 3 - Doel van de verwerking</h3>
                     <p>De persoonsgegevens worden door You Be Well uitsluitend verwerkt voor de volgende doeleinden:</p>
                     <ul>
                        <li>Het bieden van een platform voor gebruikers die hun activiteiten naar keuze kunnen boeken, en in contact kunnen komen met aanbieders (leveranciers).</li>
                        <li>Het bieden van een platform voor leveranciers en dienstverleners om hun activiteiten te promoten, boekingen te ontvangen en in contact te komen met gebruikers (potentiële klanten).</li>
                        <li>Het verlenen van een betere dienstverlening, zoals het verwerken en afhandelen van boekingen en het beheren van accounts.</li>
                        <li>Het onderhouden van relaties met u.</li>
                        <li>Het versturen van transactionele e-mails, mededelingen, belangrijke updates en marketingcommunicatie via post, e-mail en/of nieuwsbrieven.</li>
                        <li>Het faciliteren van interacties tussen gebruikers en leveranciers op het platform</li>
                        <li>Het analyseren en verbeteren van de werking, veiligheid en gebruikerservaring van het platform, inclusief het verzamelen van feedback, gebruikersinzichten en statistieken om de dienstverlening voortdurend te optimaliseren en aan te passen aan de behoeften van gebruikers en leveranciers.</li>
                        <li>Het naleven van wettelijke verplichtingen en het behandelen van geschillen of juridische claims.</li>
                        <li>Het uitvoeren van marktonderzoek en analyse om het platform verder te ontwikkelen en nieuwe functies, diensten of aanbiedingen te introduceren die relevant zijn voor gebruikers en leveranciers.</li>
                        <li>Het personaliseren van de gebruikerservaring op het platform, zoals door middel van gerichte content, aanbevelingen en gepersonaliseerde aanbiedingen die passen bij het profiel en de voorkeuren van gebruikers, mits toestemming is verleend waar nodig, en anonimiteit gewaarborgd blijft.</li>
                     </ul>

                     <p>Verwerking uitsluitend voor vastgestelde doeleinden: You Be Well zal de persoonsgegevens niet voor enig ander doel verwerken dan is vastgesteld. Eventuele wijzigingen in de doeleinden zullen tijdig en schriftelijk worden meegedeeld.</p>

                     <h3>Artikel 4 - Registratie van persoonsgegevens</h3>
                     <p>Uw persoonsgegevens worden geregistreerd in een (elektronisch) register dat voldoet aan de wettelijke vereisten en beveiligingsstandaarden. Dit register wordt onderhouden door You Be Well om de integriteit en veiligheid van gegevensverwerking te waarborgen.</p>

                     <h3>Artikel 5 - Uw rechten met betrekking tot uw gegevens</h3>
                     <p>Op grond van artikel 13 lid 2 sub b AVG heeft u de volgende rechten met betrekking tot uw persoonsgegevens:</p>
                     <ul>
                        <li>Recht op inzage in en rectificatie of wissing van uw persoonsgegevens.</li>
                        <li>Recht op beperking van de verwerking.</li>
                        <li>Recht op bezwaar tegen de verwerking.</li>
                        <li>Recht op gegevensoverdraagbaarheid.</li>
                     </ul>

                     <p>U kunt deze rechten uitoefenen door contact met ons op te nemen via <Link to="mailto:hello@youbewell.nl">hello@youbewell.nl</Link>. Ieder verzoek dient vergezeld te zijn van een kopie van een geldig identiteitsbewijs waarop uw handtekening staat, evenals het adres waarop u bereikbaar bent. Wij zullen binnen één maand reageren op uw verzoek. Afhankelijk van de complexiteit en het aantal verzoeken kan deze termijn met twee maanden worden verlengd.</p>

                     <h3>Artikel 6 - Duur van de gegevensopslag</h3>
                     <p>De door You Be Well verzamelde gegevens worden bewaard gedurende de wettelijk bepaalde termijn of zolang als noodzakelijk is voor de uitvoering van de doeleinden zoals beschreven in deze privacyverklaring. Zolang een account van een gebruiker of leverancier actief is op het You Be Well platform, worden de bijbehorende gegevens behouden om de dienstverlening te kunnen voortzetten.</p>

                     <p>Het is de eigen verantwoordelijkheid van de gebruiker of leverancier om schriftelijk melding te maken van het verzoek om het account en bijbehorende gegevens te verwijderen. Na ontvangst van een dergelijk schriftelijk verzoek zal You Be Well alle gegevens die verband houden met het account binnen een termijn van maximaal 28 dagen verwijderen of vernietigen, tenzij anders schriftelijk overeengekomen.</p>

                     <h3>Artikel 7 - Doorgifte van persoonsgegevens</h3>
                     <p>You Be Well is vrij om persoonsgegevens te verwerken met gebruik van relevante diensten, waaronder sub-verwerkers zoals <Link to="https://mandrill.com" target="_blank">Mandrill</Link> en <Link to="https://mailchimp.com" target="_blank">Mailchimp</Link> voor e-mailfunctionaliteit. Zonder voorafgaande schriftelijke toestemming zullen verder geen persoonsgegevens worden doorgegeven aan of toegankelijk gemaakt vanuit landen of organisaties waarvoor de Europese Commissie geen adequaatheidsbesluit heeft afgegeven. Indien dergelijke diensten toch worden gebruikt, zorgt You Be Well voor naleving van artikel 44 tot en met 50 van de AVG, waaronder het implementeren van standaardcontractbepalingen en passende beveiligingsmaatregelen.</p>

                     <h3>Artikel 8 - Beveiliging van persoonsgegevens</h3>
                     <p>You Be Well neemt passende technische en organisatorische maatregelen om uw persoonsgegevens te beschermen tegen verlies of onrechtmatige verwerking. Deze maatregelen worden regelmatig geëvalueerd en aangepast aan de stand van de techniek en beveiligingsrisico's. Alleen bevoegde medewerkers van You Be Well hebben toegang tot persoonsgegevens, en zij handelen volgens de geldende beveiligingsprotocollen.</p>

                     <h3>Artikel 9 - Commerciële aanbiedingen</h3>
                     <p>You Be Well kan u commerciële aanbiedingen sturen indien u hiervoor toestemming heeft gegeven. Als u deze niet (meer) wenst te ontvangen, kunt u zich afmelden via een e-mail aan <Link to="mailto:hello@youbewell.nl">hello@youbewell.nl</Link>. Uw gegevens worden niet voor commerciële doeleinden gedeeld met partners van You Be Well, tenzij hiervoor afzonderlijke toestemming is gegeven.</p>

                     <h3>Artikel 10 - Wettelijke verplichtingen</h3>
                     <p>In geval van een overtreding van wet- of regelgeving waarvoor de autoriteiten persoonsgegevens nodig hebben die door de Beheerder zijn verzameld, worden deze gegevens verstrekt na een uitdrukkelijk en gemotiveerd verzoek van die autoriteiten. Zodra deze gegevens zijn verstrekt, vallen zij niet meer onder de bescherming van deze privacyverklaring.</p>

                     <h3>Artikel 11 - Toepasselijk recht</h3>
                     <p>Op deze privacyverklaring is uitsluitend Nederlands recht van toepassing. Eventuele geschillen in verband met deze voorwaarden vallen onder de exclusieve bevoegdheid van de rechtbank in het arrondissement waarin You Be Well is gevestigd, behoudens andersluidende wettelijke verplichtingen.</p>

                     <h3>Artikel 12 - Wijzigingen in de privacyverklaring</h3>
                     <p>You Be Well behoudt zich het recht voor om deze privacyverklaring tussentijds aan te passen. Kleine aanpassingen die geen wezenlijke impact hebben op de rechten en verplichtingen van gebruikers en leveranciers kunnen zonder voorafgaande melding worden doorgevoerd.</p>

                     <p>In geval van substantiële wijzigingen die de manier waarop persoonsgegevens worden verwerkt aanzienlijk beïnvloeden, zal You Be Well gebruikers en leveranciers op de hoogte brengen via e-mail of een kennisgeving op het platform, zodat zij zich bewust zijn van de wijzigingen en hun rechten kunnen uitoefenen indien gewenst.</p>

                     <h3>Artikel 13 - Verwerkers en Sub-verwerkers</h3>
                     <p>You Be Well kan gebruikmaken van derde partijen (sub-verwerkers) voor de verwerking van persoonsgegevens, zoals hosting- en e-maildiensten. Deze verwerkers verwerken gegevens uitsluitend in opdracht van You Be Well en zijn gebonden aan dezelfde verplichtingen inzake gegevensbescherming als You Be Well. Een overzicht van sub-verwerkers kan op verzoek worden verstrekt.</p>

                     <h3>Artikel 14 - Beveiligingsmaatregelen</h3>
                     <p>You Be Well neemt passende technische en organisatorische maatregelen om de bescherming van persoonsgegevens te waarborgen. Ondanks deze inspanningen kan You Be Well niet garanderen dat de gegevens volledig beschermd zijn tegen alle mogelijke dreigingen, maar we ondernemen alle redelijke stappen om dit risico te minimaliseren.</p>

                     <h3>Artikel 15 - Minderjarigen</h3>
                     <p>Het platform van You Be Well is niet bedoeld voor personen jonger dan 16 jaar. You Be Well verzamelt niet bewust persoonsgegevens van minderjarigen. Indien blijkt dat persoonsgegevens van een minderjarige zonder toestemming van de ouders of voogd zijn verzameld, zullen deze gegevens zo snel mogelijk worden verwijderd.</p>

                     <h3>Artikel 16 - Klachten</h3>
                     <p>Indien u een klacht heeft over de verwerking van uw persoonsgegevens, kunt u contact met ons opnemen via <Link to="mailto:hello@youbewell.nl">hello@youbewell.nl</Link>. Mocht u ontevreden zijn over de afhandeling van uw klacht, dan heeft u het recht om een klacht in te dienen bij de Autoriteit Persoonsgegevens.</p>

                     <h3>Artikel 17 - Data-inbreuken</h3>
                     <p>In het geval van een data-inbreuk die mogelijk nadelige gevolgen heeft voor uw persoonsgegevens, zal You Be Well u en de relevante autoriteiten zo snel mogelijk op de hoogte stellen, in overeenstemming met de AVG.</p>

                     <h3>Artikel 18 - Gebruik van Cookies en Vergelijkbare Technologieën</h3>
                     <p>You Be Well kan gebruikmaken van cookies en vergelijkbare technologieën om het platform te optimaliseren, het gebruiksgemak te vergroten, en gepersonaliseerde content aan te bieden. Gebruikers kunnen hun cookie-instellingen beheren via hun browser of platforminstellingen. Meer informatie over het gebruik van cookies vindt u in ons cookiebeleid.</p>

                     <h3>Artikel 19 - Contact</h3>
                     <p>Voor verzoeken, vragen of verdere informatie over de bescherming van uw persoonsgegevens kunt u contact opnemen via <Link to="mailto:hello@youbewell.nl">hello@youbewell.nl</Link>.</p>

                     <p>Deze privacyverklaring is van toepassing sinds 1 januari 2024. Laatst gewijzigd op 11-11-2024.</p>

                  </section>

               }

               {path === 'content' &&

                  <section id="learn-content">

                     <h2>Contentbeleid Aanbieders</h2>
                     <p>Wij geloven dat het delen van aantrekkelijke content niet alleen jouw profiel versterkt, maar ook bijdraagt aan een betere ervaring voor onze gebruikers. Hier zijn enkele richtlijnen en tips om je te helpen bij het creëren van aantrekkelijke content die jouw diensten optimaal presenteert en je profiel nog aantrekkelijker maakt voor potentiële klanten.</p>

                     <h3>Afbeeldingsrichtlijnen:</h3>
                     <ul>
                        <li>Houd afbeeldingen onder 2MB en maximaliseer de afmetingen tot 2000 x 2000 voor optimale weergave.</li>
                        <li>Gebruik scherpe afbeeldingen met een goede resolutie om wazigheid te voorkomen.</li>
                        <li>Tip: Gebruik een fotobewerkingsprogramma, zoals Canva, om eenvoudig afbeeldingen te bewerken en te optimaliseren voor onze richtlijnen.</li>
                        <li>Plaats de afbeelding die jouw dienst het beste vertegenwoordigt als eerste foto op je profiel.</li>
                        <li>Voorbeelden van geschikte afbeeldingen zijn: actiefoto's van jou tijdens het uitvoeren van de dienst, afbeeldingen van je praktijkruimte, ontspannende klanten en duidelijke illustraties van je diensten, zoals reflexpunten bij voetreflexologie.</li>
                        <li>Voeg ook een foto van jezelf toe, maar plaats deze niet als eerste foto.</li>
                     </ul>
                     <h3>Tips voor promotie:</h3>
                     <ul>
                        <li>Laat actiefoto's zien van jou in actie, ontspannende klanten, sfeervolle ruimtes en tevreden cliënten.</li>
                        <li>Gebruik afbeeldingen om de sfeer en ervaring van jouw diensten over te brengen.</li>
                     </ul>
                     <h3>Over de activiteit/omschrijving:</h3>
                     <ul>
                        <li>Beschrijf je activiteit kort en krachtig, alsof je direct communiceert met potentiële klanten.</li>
                        <li>Identificeer de doelgroep en hun behoeften, zoals stressvermindering, gezondheid en welzijn.</li>
                        <li>Benadruk de voordelen en resultaten die klanten kunnen verwachten.</li>
                        <li>Wees specifiek, gebruik eenvoudige taal en benadruk unieke eigenschappen van jouw diensten.</li>
                     </ul>
                     <p>Door deze richtlijnen te volgen, zal je profiel op ons platform nog aantrekkelijker worden en zul je meer boekingen genereren.</p>

                  </section>

               }

               {path === "terms-and-conditions" &&

                  <div id="terms-switcher">

                     <a 
                        className="switch"
                        download="user_terms.pdf"
                        href={require('../assets/user_terms.pdf')}
                     >
                        <UserIcon />
                        <h3>Gebruiker</h3>
                     </a>

                     <a 
                        className="switch"
                        download="provider_terms.pdf"
                        href={require('../assets/provider_terms.pdf')}
                     >
                        <EditorIcon />
                        <h3>Aanbieder</h3>
                     </a>

                     <a 
                        className="switch"
                        download="employer_terms.pdf"
                        href={require('../assets/employer_terms.pdf')}
                        >
                        <EmployerIcon />
                        <h3>Werkgever</h3>
                     </a>

                  </div>

               }

            </div>

         </section>

      </main>

   )

}