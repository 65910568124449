import { Route, Routes } from 'react-router-dom';

import ActivityPage from './pages/ActivityPage';
import CalculatorPage from './pages/CalculatorPage';
import CreateActivityPage from './pages/CreateActivityPage';
import DocsPage from './pages/DocsPage';
import HomePage from './pages/HomePage';
import HubPage from './pages/HubPage';
import LoginPage from './pages/LoginPage';
import OverviewPage from './pages/OverviewPage';
import PartnerPage from './pages/PartnerPage';
import RatingPage from './pages/RatingPage';
import ReferralPage from './pages/ReferralPage';
import WelcomePage from './pages/WelcomePage';

import { AuthProvider } from './contexts/AuthContext';
import CookieBanner from './components/cookies/CookieBanner';
import Footer from './components/Footer';
import LeadinfoScript from './components/cookies/LeadinfoScript';
import Nav from './components/Nav';
import NotificationContainer from './components/NotificationContainer';
import { NotificationProvider } from './contexts/NotificationContext';
import { ProtectedRoute } from './components/routes/ProtectedRoute';
import { RefreshRoute } from './components/routes/RefreshRoute';
import useViewportHeight from './hooks/useViewportHeight';

const App = () => {    

  useViewportHeight();

  return (
    <AuthProvider>
      <NotificationProvider>
        <Nav />
        <Routes>
            <Route index element={<RefreshRoute><HomePage /></RefreshRoute>} />
            <Route path="/activities" element={<RefreshRoute><OverviewPage /></RefreshRoute>} />
            <Route path="/activities/:path" element={<RefreshRoute><ActivityPage /></RefreshRoute>} />
            <Route path="/activities/:path/edit" element={<ProtectedRoute><ActivityPage edit /></ProtectedRoute>} />
            <Route path="/activities/new" element={<ProtectedRoute><CreateActivityPage /></ProtectedRoute>} />
            <Route path="/partners" element={<PartnerPage />} />
            <Route path="/welcome" element={<ProtectedRoute><WelcomePage /></ProtectedRoute>} />
            <Route path="/hub" element={<ProtectedRoute><HubPage /></ProtectedRoute>} />
            <Route path="/hub/chats" element={<ProtectedRoute><HubPage /></ProtectedRoute>} />
            <Route path="/hub/chats/:path" element={<ProtectedRoute><HubPage /></ProtectedRoute>} />
            <Route path="/hub/profile" element={<ProtectedRoute><HubPage /></ProtectedRoute>} />
            <Route path="/hub/financial" element={<ProtectedRoute admin><HubPage /></ProtectedRoute>} />
            <Route path="/hub/wellbits" element={<ProtectedRoute><HubPage /></ProtectedRoute>} />
            <Route path="/hub/admin" element={<ProtectedRoute admin><HubPage /></ProtectedRoute>} />
            <Route path="/bookings/:path/rate" element={<ProtectedRoute><RatingPage /></ProtectedRoute>} />
            <Route path="/docs/:path" element={<RefreshRoute><DocsPage /></RefreshRoute>} />
            <Route path="/calculator" element={<CalculatorPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LoginPage />} />
            <Route path="/signup" element={<LoginPage />} />
            <Route path="/signup/employee" element={<LoginPage />} />
            <Route path="/request-password" element={<LoginPage />} />
            <Route path="/reset-password" element={<LoginPage />} />
            <Route path="/r/:path" element={<ReferralPage />} />
            <Route path="*" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
        </Routes>
        <CookieBanner />
        <Footer />
        <NotificationContainer />
        <LeadinfoScript />
      </NotificationProvider>
    </AuthProvider>
  );
}

export default App;
