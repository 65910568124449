import { useEffect, useState } from 'react';

import Input from './inputs/Input';
import Select from './inputs/Select';

import './Offerings.scss';
import Button from './inputs/Button';

export default function Offerings(props) {

   const [offerings, setOfferings] = useState([]);
   const [updateOfferings, setUpdateOfferings] = useState([]);

   const [defaultOptions, setDefaultOptions] = useState([]);
   const [optionMap, setOptionMap] = useState({});

   // set the offering to be selected
   const [selectedOffering, setSelectedOffering] = useState(null);
   const [offeringsEditable, setOfferingsEditable] = useState(true);

   const addOffering = () => {

      setOfferingsEditable(false);
      const newOfferings = [...offerings];
      newOfferings.push({
         id: "",
         price: 1,
         activity_option_id: "",
         title: "",
         description: "",
         sorting_order: newOfferings.length,
         edit: true,
         allow_confirm: false,
      });
      setOfferings(newOfferings);

      setTimeout(() => {
         const offeringTitleInput = document.getElementById('offering-title')
         if (offeringTitleInput) {
            offeringTitleInput.focus();
         };
      }, 50);

   }  

   const checkAllowConfirm = (key) => {

      let allow = true;
      for (const field of ["price", "activity_option_id", "title", "description"]) {
         if (!offerings[key][field]) {
            allow = false;
            break;
         }
      }
      updateOfferingField(key, "allow_confirm", allow);

   }

   const updateOfferingField = (key, field, value) => {

      const newOfferings = [...offerings];
      newOfferings[key][field] = value;
      setOfferings(newOfferings);

      if (field == "edit") {
         setOfferingsEditable(!value);
      }

      if (field !== "allow_confirm") {
         checkAllowConfirm(key);
      }

   }

   const updateShownOfferings = () => {
      
      const newUpdateOfferings = [];
      for (const offering of offerings) {
         if (offering.update && !offering.edit || offering.delete) {
            const newOffering = {}
            for (const field of ["id", "title", "description", "activity_option_id", "price", "delete", "sorting_order"]) {
               newOffering[field] = offering[field];
            }
            newUpdateOfferings.push(newOffering);
         }
      }
      setUpdateOfferings(newUpdateOfferings);

   }

   const selectOffering = (e, key) => {

      const alreadySelected = selectedOffering == key;
      setSelectedOffering(alreadySelected ? null : key);

   }

   const updateSortingOrder = (key, moveUp) => {

      const newOfferings = [...offerings];
      [newOfferings[key + (moveUp ? 1 : -1)], newOfferings[key]] = [newOfferings[key], newOfferings[key + (moveUp ? 1 : -1)]];
      for (let i = 0; i < newOfferings.length; i++) {
         newOfferings[i].update = true;
         newOfferings[i].sorting_order = i;
      }
      setOfferings(newOfferings);
   }

   useEffect(() => {

      if (props.offerings) {
         const newOfferings = props.offerings;
         for (const offering of newOfferings) {
            offering.edit = false;
            offering.delete = false;
            offering.update = false;
         }
         setOfferings(newOfferings);
      }

   }, [props]);

   useEffect(() => {
      
      if (props.defaultOptions && props.optionMap) {
         props.defaultOptions.sort();
         setDefaultOptions(props.defaultOptions);
         setOptionMap(props.optionMap);
      }

   }, [props]);

   const allowSubmit = () => {

      let allow = true;
      for (const offering of offerings) {
         if (offering.edit && !offering.delete) {
            allow = false;
         }
      }
      
      const inputs = document.getElementById('offerings').parentElement.getElementsByTagName('input');
      for (const input of inputs) {
         if (input.getAttribute('type') === 'submit') {
            if (allow) {
               input.classList.remove('disabled');
            } else {
               input.classList.add('disabled');
            }
            break;
         }
      }

   }

   useEffect(() => {
      
      updateShownOfferings();
      allowSubmit();

   }, [offerings]);

   const loggedIn = Object.keys(props.user).length > 0;
   let hasEmployerCredit = false;
   if (loggedIn) {
      hasEmployerCredit = props.user.num_tokens > 0;
   }

   return (
      <div id="offerings" className={props.edit ? "edit" : ""}>

         {offerings.map((offering, key) => {
            
            if (offering.delete) {
               return <></>;
            }

            if (offering.edit) {
               return (
                  <div key={key} className="offering edit">

                     {offering.id &&
                        <svg 
                           className="offering-remove" 
                           onClick={() => updateOfferingField(key, "delete", true)}
                           clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m20.015 6.506h-16v14.423c0 .591.448 1.071 1 1.071h14c.552 0 1-.48 1-1.071 0-3.905 0-14.423 0-14.423zm-5.75 2.494c.414 0 .75.336.75.75v8.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-8.5c0-.414.336-.75.75-.75zm-4.5 0c.414 0 .75.336.75.75v8.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-8.5c0-.414.336-.75.75-.75zm-.75-5v-1c0-.535.474-1 1-1h4c.526 0 1 .465 1 1v1h5.254c.412 0 .746.335.746.747s-.334.747-.746.747h-16.507c-.413 0-.747-.335-.747-.747s.334-.747.747-.747zm4.5 0v-.5h-3v.5z" fillRule="nonzero"/></svg>
                     }

                     <div className="offering-info">
                        <Input 
                           type="text"
                           id="offering-title"
                           placeholder="Titel"
                           maxLength="20"
                           onInput={(e, newValue) => {updateOfferingField(key, "title", newValue)}}
                           defaultValue={offering.title}
                           example="Bijv. Pilates - 30 min."
                           />
                        <Select 
                           id="offering-option"
                           placeholder="Type activiteit"
                           options={defaultOptions}
                           defaultValue={offering.activity_option_id ? offering.activity_option_id : null}
                           onChange={(e, selectedValue) => { updateOfferingField(key, "activity_option_id", selectedValue); }}
                           zIndex={99}
                           readOnly
                           />

                        <Input 
                           id="offering-description"
                           type="text"
                           defaultValue={offering.description}
                           example="Beschrijf je service"
                           onInput={(e) => { updateOfferingField(key, "description", e.target.value); }}
                           maxLength="80"
                           multiline multilineSmall
                           />
                     </div>
                     <div className="offering-price">
                        
                        {offering.allow_confirm &&
                           <span id="offering-confirm"
                              onClick={() => { updateOfferingField(key, "edit", false); updateOfferingField(key, "update", true);  }}>
                              Bevestigen
                           </span>
                        }

                        <Input 
                           type="text"
                           id="offering-price"
                           placeholder="Tarief"
                           inputMode="numeric"
                           maxLength="3"
                           defaultValue={String(offering.price)}
                           onInput={(e) => { updateOfferingField(key, "price", parseInt(e.target.value) ? parseInt(e.target.value) : 1); }}
                           icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.728 20.517c-3.488 0-5.613-2.461-6.443-5.517h6.715l.333-2h-7.398c-.059-.664-.064-1.335-.014-2h7.746l.333-2h-7.755c.786-3.106 2.855-5.626 6.154-5.626 1.133 0 2.391.203 3.836.62l.765-3.162c-1.854-.552-3.616-.832-5.244-.832-5.959 0-9.541 4.152-10.594 9h-2.162l-.333 2h2.203c-.049.666-.051 1.334-.007 2h-2.53l-.333 2h3.145c1.033 4.848 4.664 9 11.085 9 1.5 0 3.004-.276 4.476-.821l-.883-3.23c-1.048.378-2.088.568-3.095.568z"/></svg>}
                           numericOnly />

                     </div>
                  </div>
               )
            }

            return (
               <div 
                  className={"offering" + 
                     (offeringsEditable ? " editable" : "") +
                     (selectedOffering == key ? " selected" : "") + 
                     (!props.edit && props.user && props.user.num_tokens < offering.price ? " too-expensive" : "" )}
                  onClick={props.edit ? 
                     (e) => { 
                        if (offeringsEditable && !e.target.classList.contains("move-offering") && !e.target.parentElement.classList.contains("move-offering")) {
                           updateOfferingField(key, "edit", true) ;
                        }
                     } : 
                     (e) => { selectOffering(e, key); }}
                  >

                  {props.edit &&
                     <svg 
                        className="offering-edit"
                        clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m4.481 15.659c-1.334 3.916-1.48 4.232-1.48 4.587 0 .528.46.749.749.749.352 0 .668-.137 4.574-1.492zm1.06-1.061 3.846 3.846 11.321-11.311c.195-.195.293-.45.293-.707 0-.255-.098-.51-.293-.706-.692-.691-1.742-1.74-2.435-2.432-.195-.195-.451-.293-.707-.293-.254 0-.51.098-.706.293z" fillRule="nonzero"/></svg>}

                  <div className="offering-info">

                     <h4>{offering.title}</h4>
                     <span className="offering-option">{optionMap ? optionMap[offering.activity_option_id].pretty_name: ""}</span>
                     <p>{offering.description}</p>
                  </div>
                  <span className="offering-price">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.728 20.517c-3.488 0-5.613-2.461-6.443-5.517h6.715l.333-2h-7.398c-.059-.664-.064-1.335-.014-2h7.746l.333-2h-7.755c.786-3.106 2.855-5.626 6.154-5.626 1.133 0 2.391.203 3.836.62l.765-3.162c-1.854-.552-3.616-.832-5.244-.832-5.959 0-9.541 4.152-10.594 9h-2.162l-.333 2h2.203c-.049.666-.051 1.334-.007 2h-2.53l-.333 2h3.145c1.033 4.848 4.664 9 11.085 9 1.5 0 3.004-.276 4.476-.821l-.883-3.23c-1.048.378-2.088.568-3.095.568z"/></svg>
                     {parseFloat(offering.price).toFixed(2).replace('.', ',')}<br />
                     {(props.edit || hasEmployerCredit) &&
                        <span
                           className="offering-price-vat"
                           >incl. {optionMap[offering.activity_option_id].vat_percentage}% btw</span>
                     }

                     {(!props.edit && !hasEmployerCredit) &&
                        <span
                           className="offering-price-vat"
                           >incl. btw</span>
                     }

                  </span>

                  {
                     props.edit && offeringsEditable &&
                        <>
                        
                        {key > 0 &&
                           <svg 
                              className="move-offering left"
                              onClick={() => { updateSortingOrder(key, false) }}
                              xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/></svg> 
                        }

                        {key < offerings.length - 1 &&
                           
                           <svg 
                              className="move-offering right"
                              onClick={() => { updateSortingOrder(key, true) }}
                              xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                              </svg>
                        }
                        
                        </>
                  }


               </div>
            );

         })}

         {props.edit &&
            <>
               
               {offeringsEditable && 
                  <div 
                     className="offering add"
                     onClick={addOffering}
                     >
                     <h4>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z"/></svg><br />
                        Service toevoegen
                     </h4>
                  </div>
               }

               <input type="hidden" name="offerings" id="offerings-value" value={JSON.stringify(updateOfferings)} />

            </>
         }

         {!props.edit &&
            <input type="hidden" name="booking" id="offerings-booking" value={selectedOffering !== null ? JSON.stringify({
               id: offerings[selectedOffering].id,
               price: offerings[selectedOffering].price,
               activity_option_id: offerings[selectedOffering].activity_option_id,
               title: offerings[selectedOffering].title,
               description: offerings[selectedOffering].description,
            }) : JSON.stringify({})} />
            }

         {!props.edit &&
            <>
               {loggedIn &&
                  <p id="offerings-balance">
                     <span>Je saldo is <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.728 20.517c-3.488 0-5.613-2.461-6.443-5.517h6.715l.333-2h-7.398c-.059-.664-.064-1.335-.014-2h7.746l.333-2h-7.755c.786-3.106 2.855-5.626 6.154-5.626 1.133 0 2.391.203 3.836.62l.765-3.162c-1.854-.552-3.616-.832-5.244-.832-5.959 0-9.541 4.152-10.594 9h-2.162l-.333 2h2.203c-.049.666-.051 1.334-.007 2h-2.53l-.333 2h3.145c1.033 4.848 4.664 9 11.085 9 1.5 0 3.004-.276 4.476-.821l-.883-3.23c-1.048.378-2.088.568-3.095.568z"/></svg><span>{props.user.num_tokens} incl. btw.</span></span>
                     <Button
                        name="Tegoed opwaarderen"
                        href="https://forms.office.com/e/37JjHcrU7B?origin=lprLink"
                        target="_blank"
                     />
                  </p>
               }
               <ul id="offerings-guarantees">
                  <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>Aanbieders voldoen aan de You Be Well-kwaliteitsstandaarden.</li>
                  <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>De aanbieder plant in overleg met jou levering van de dienst in.</li>
                  <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>Toch van gedachten veranderd? Je kunt altijd annuleren.</li>
               </ul>
            </>
         }

      </div>
   );

}