import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";

import Button from "./Button";
import Input from "./Input";
import useTokenStorage from "../../hooks/useTokenStorage";

import './Form.scss';


export default function Form(props) {

   const [loading, setLoading] = useState(false);
   const [submitted, setSubmitted] = useState(false);
   const navigate = useNavigate();

   const [getTokens, saveToken, removeToken, clearTokens, getToken, getTokenExpriy, refreshToken] = useTokenStorage();

   const [disabled, setDisabled] = useState(true);

   useEffect(() => {
      setDisabled(props.disabled);
   }, [props.disabled]);

   useEffect(() => {

      if (!props.onChange || !formRef.current || !Object.keys(props).includes('mutationObserver')) return;

      const mutationObserver = new MutationObserver((mutationsList) => {
         mutationsList.forEach((mutation) => {
            props.onChange();
         });
      });
   
      const inputsToObserve = formRef.current.querySelectorAll('input[type="hidden"]');
      inputsToObserve.forEach((input) => {
         mutationObserver.observe(input, {
            attributes: true,
            childList: true,
            subtree: true,
         });
      });

       return () => {
         mutationObserver.disconnect();
       };

   }, [props.onChange]);

   const formRef = useRef(null);
   const disableForm = () => {

      setTimeout(() => {

         if (props.disabled === false && formRef.current) {
            const errorInputs = formRef.current.getElementsByClassName('error');
            setDisabled(errorInputs.length > 0);
         }

      }, 100);

   }

   const handleResponse = (data, form) => {

      setTimeout(() => {
         if (props.sentMessage) {
            const feedback = document.createElement('p');
            feedback.textContent = props.sentMessage;
            form.outerHTML = feedback.outerHTML;
         }
         setLoading(false);
      }, 500);

      return data;

   }

   const onSubmit = (e) => {

      e.preventDefault();

      const checkForm = new FormData(e.target);
      if (checkForm.get("id_4128592")) {
         return;
      }

      // validate inputs
      let valid = true;

      const textInputs = [...e.target.getElementsByTagName('input'), ...e.target.getElementsByTagName('textarea')];
      for (const textInput of textInputs) {

         if (textInput.classList.contains('select-search') || textInput.classList.contains('options-search')) {
            continue;
         }

         let inputValid = true, inputError = null;

         // check if already in error state
         if (textInput.classList.contains('error')) {
            valid = false;
            inputValid = false;
            continue;
         }


         if (textInput.getAttribute('required') !== null || textInput.value !== "") {
            if (textInput.getAttribute('type') == 'hidden' && textInput.value == "") {
               inputError = 'Vul een waarde in.';
               inputValid = false;
            } else if (textInput.value == "") {
               let placeHolderText;
               
               // the placeholder is one element further for selects
               if (textInput.parentElement.classList.contains('select')) {
                  placeHolderText = textInput.nextElementSibling.nextElementSibling.textContent.toLowerCase().replace('*', '');
               } else {
                  placeHolderText = textInput.nextElementSibling.textContent.toLowerCase().replace('*', '');
               }

               inputError = 'Vul je ' + placeHolderText + ' in.';
               inputValid = false;
            } else if (textInput.getAttribute('type') == "email" && !/.+@.+\..{2,}/.test(textInput.value)) {
               inputError = 'Vul een correct e-mailadres in.';
               inputValid = false;
            } else if (textInput.getAttribute('name') == "tel" && !/^[0-9\s\-+]{6,}$/.test(textInput.value)) {
               inputError = 'Vul een correct telefoonnummer in.';
               inputValid = false;
            } else if (textInput.getAttribute('name') == "zip" && !/^\s*[0-9]{4,}\s*[A-z]{2}\s*$/.test(textInput.value)) {
               inputError = 'Vul een correcte postcode in.';
               inputValid = false;
            } else if (textInput.getAttribute('name') == 'check-password') {
               const passwordInput = textInput.parentElement.previousElementSibling.previousElementSibling.getElementsByTagName('input')[0];
               if (passwordInput.value !== textInput.value) {
                  inputError = 'Deze wachtwoorden komen niet overeen.';
                  inputValid = false;
               }
            }
         }

         if (inputValid) {
            textInput.classList.remove('error');
         } else {
            valid = false;
            textInput.classList.add('error');
            if (textInput.parentElement.nextElementSibling == null || !textInput.parentElement.nextElementSibling.classList.contains('error-message')) {
               const errorMessage = document.createElement('p');
               errorMessage.classList.add('error-message');
               errorMessage.textContent = inputError;
               textInput.parentElement.insertAdjacentElement("afterend", errorMessage);
            }

         }

      }

      // submit if all are valid
      if (valid) {

         const formData = new FormData(e.target);
         setLoading(true);

         const requestOptions = {
            method: 'post',
            headers: {},
            body: formData
         }

         const access_token = getToken('access_token');
         const access_token_expiry = getTokenExpriy('access_token');
         const refresh_token_expiry = getTokenExpriy('refresh_token');

         if (e.target.getAttribute("action")) {

            if (access_token && access_token_expiry && refresh_token_expiry) {
               if (Date.now() > access_token_expiry && Date.now() <= refresh_token_expiry) {
                  refreshToken()
                        .then(() => {
                           continueWithFetch();
                        })
                        .catch(error => {
                           handleRefreshError(error);
                        });
               } else if (Date.now() + 5 > refresh_token_expiry && window.location.pathname != "/login") {
                  redirectToLogin();
               } else {
                  continueWithFetch();
               }
            } else {
               continueWithFetch();
            }

         } else {
            setLoading(false);
            props.submitCallback(e, null);
         }

         function continueWithFetch() {
             const new_access_token = getToken('access_token');

             if (new_access_token) requestOptions.headers['Authorization'] = 'Bearer ' + new_access_token;

             fetch(e.target.getAttribute('action'), requestOptions)
                 .then(response => response.json())
                 .then(data => handleResponse(data, e.target))
                 .then(data => {
                     if (props.submitCallback) {
                         setTimeout(() => {
                             props.submitCallback(e, data);
                         }, 500);
                     }
                 })
                 .catch(error => {
                     handleFetchError(error);
                 });
         }
         
         function handleRefreshError(error) {
             // Handle token refresh error
         }
         
         function redirectToLogin() {
             const queryPath = window.location.pathname;
             navigate('/login?redirect=' + encodeURIComponent(queryPath), { replace: true });
             clearTokens();
             setLoading(false);
         }
         
         function handleFetchError(error) {
             // Handle fetch error
         }

      }

   }

   return (
      <form 
         id={props.id}
         action={props.action}
         ref={formRef}
         className={(props.oneLineSubmit ? "oneline-form " : "" ) + props.className}
         onSubmit={props.customSubmit ? props.customSubmit : onSubmit}
         onKeyDown={(e) => { if (e.key == 'Enter' && e.target.tagName != 'TEXTAREA') e.preventDefault(); }}
         onChange={disableForm}
         autoComplete={props.autoComplete}
         noValidate>
         
         {props.children}

         {!props.noHoneyPot &&
            <Input honeypot />
         }

         <div className={"form-buttons" + (props.additionalButton ? " multiple" : "")}>
            {props.additionalButton}

            {(!props.oneLineSubmit && !props.noSubmit) && 
               <Button 
                  id={props.id ? props.id + "-submit": ""}
                  name={props.submitBtnText}
                  submit
                  disabled={disabled}
                  cta={!props.noCta}
                  />
            }

         </div>

         {props.oneLineSubmit &&
            <>
               <input id={props.id + "-submit"} type="submit" value="" className="oneline-submit" />
               <label 
                  htmlFor={props.id + "-submit"}
                  className={"oneline-submit-container" + (disabled ? " disabled": "")}>
                  {props.oneLineSubmitIcon}
               </label>
            </>
         }

         {(loading && !props.noLoad) &&
            <div className="loader">
               <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
               <p>{props.loadText ? props.loadText : 'Verzenden...'}</p>
            </div>
            }

      </form>
   );

}