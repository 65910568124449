import { useEffect, useState } from 'react';

import Input from './inputs/Input';

import './Table.scss';

export default function Table(props) {

   // pagination
   const [page, setPage] = useState(0);
   const [numPages, setNumPages] = useState(1);

   // search
   const [searchKeys, setSearchKeys] = useState([]);

   // data storage
   const [headers, setHeaders] = useState({});
   const [data, setData] = useState([]);

   const sortData = (field) => {

      const parseDutchDate = (dateStr) => {
         const [day, month, year] = dateStr.split('-');
         return new Date(year, month - 1, day);
      }

      const compareFn = (a, b) => {

         let fieldA = a[field], fieldB = b[field];
         console.log(fieldA, fieldB);
         const dataType = headers[field].type;
         if (dataType === "date") {
            fieldA = parseDutchDate(fieldA);
            fieldB = parseDutchDate(fieldB);
            console.log(fieldA, fieldB);
         } else if (dataType === "int") {
            fieldA = fieldA ? parseInt(fieldA.replace(/[^\d]/, '')) : null; 
            fieldB = fieldB ? parseInt(fieldB.replace(/[^\d]/, '')) : null;
         }

         console.log(fieldA, fieldB);

         const ascending = headers[field].sortDirection === null;
         if (fieldA < fieldB) {
            return ascending ? -1 : 1;
         } else if (fieldA > fieldB) {
            return ascending ? 1 : -1;
         }
         return 0;

      }
   
      const sortDirection = headers[field].sortDirection;
      const newSortDirection = (sortDirection === "asc" ? "desc" : (sortDirection === null ? "asc" : null))

      if (newSortDirection === null) {
         setData([...props.data]);
      } else {
         const newData = [...data];
         newData.sort((a, b) => {
            return compareFn(a, b)
         });
         setData(newData);
      }

      setHeaders(prevHeaders => {
         const newHeaders = {...prevHeaders};
         Object.keys(newHeaders).forEach(headerKey => {
            newHeaders[headerKey] = {
               ...newHeaders[headerKey],
               sortDirection: headerKey === field ? newSortDirection : null
            }
         })
         return newHeaders
      });

   }

   const searchData = (query) => {

      const regex = new RegExp(query.toLowerCase());
      const newData = [];
      for (let i = 0; i < props.data.length; i++) {
         if (query === '' || regex.test(searchKeys[i])) {
            newData.push(props.data[i]);
         }
      }
      setData(newData);
      setNumPages(parseInt(newData.length / props.rowsPerPage) + (newData.length % props.rowsPerPage ? 1 : 0));

   }

   useEffect(() => {

      if (props.headers && props.data && props.headers.length) {

         // data
         const newData = [...props.data]
         setData(newData);
         const newHeaders = {};
         for (const header of props.headers) {
            newHeaders[header.field] = {
               ...header,
               sortDirection: null
            };
         }
         setHeaders(newHeaders);

         // pagination
         setNumPages(parseInt(props.data.length / props.rowsPerPage) + (props.data.length % props.rowsPerPage ? 1 : 0))

         // search
         const searchableFields = [];
         for (const headerKey of Object.keys(headers)) {
            if (headers[headerKey].searchable) {
               searchableFields.push(headerKey);
            }
         }

         const newSearchKeys = [];
         for (const row of props.data) {
            let searchKey = '';
            for (const field of searchableFields) {
               if (row[field]) {
                  searchKey += ' ' + row[field].toLowerCase();
               }
            }
            newSearchKeys.push(searchKey.trim());
         }
         setSearchKeys(newSearchKeys);

      }

   }, [props.data]);

   const headerKeys = Object.keys(headers);

   return (

      <div className="table-wrapper">

         {!props.noSearch &&
            <Input 
               type="text"
               onInput={(e, value) => searchData(e.target.value)}
               placeholder="Zoek gebruiker"
               icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9.145 18.29c-5.042 0-9.145-4.102-9.145-9.145s4.103-9.145 9.145-9.145 9.145 4.103 9.145 9.145-4.102 9.145-9.145 9.145zm0-15.167c-3.321 0-6.022 2.702-6.022 6.022s2.702 6.022 6.022 6.022 6.023-2.702 6.023-6.022-2.702-6.022-6.023-6.022zm9.263 12.443c-.817 1.176-1.852 2.188-3.046 2.981l5.452 5.453 3.014-3.013-5.42-5.421z"/></svg>}
               />
         }

         <div className="responsive-table">
            <table>
               <thead>
                  <tr>
                     {[headerKeys.map((headerKey, key) => { 
                        
                        const header = headers[headerKey];

                        return(
                           <th  
                              className={
                                 (header.sortable ? "sortable": "") +
                                 (header.alignRight ? " align-right" : "")
                              }
                              onClick={(header.sortable ? () => { sortData(header.field) } : null)}
                              key={key}>
                              
                              <span>

                                 {header.label}
                              
                                 {header.sortable &&

                                    <>

                                       {!header.sortDirection &&
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 3.202l3.839 4.798h-7.678l3.839-4.798zm0-3.202l-8 10h16l-8-10zm3.839 16l-3.839 4.798-3.839-4.798h7.678zm4.161-2h-16l8 10 8-10z"/></svg>
                                       }

                                       {header.sortDirection === 'asc' &&
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 3.202l3.839 4.798h-7.678l3.839-4.798zm0-3.202l-8 10h16l-8-10zm8 14h-16l8 10 8-10z"/></svg>
                                       }

                                       {header.sortDirection === 'desc' &&
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0l-8 10h16l-8-10zm3.839 16l-3.839 4.798-3.839-4.798h7.678zm4.161-2h-16l8 10 8-10z"/></svg>
                                       }

                                    </>
                                    
                                 }

                              </span>
                              
                           </th>
                        ) 
                     })]}
                  </tr>
               </thead>
               <tbody>
                  {data.map((row, key) => {

                     if (!(key >= page * props.rowsPerPage && key < (page + 1) * props.rowsPerPage)) {
                        return;
                     }

                     return (
                        <tr
                           key={key}
                           >
                           
                           {headerKeys.map((headerKey, key) => {

                              const header = headers[headerKey];  
                              const showValue = row[header.field] ? row[header.field] : 'Geen';

                              return (
                                 <td 
                                    key={key}
                                    className={
                                       (showValue === 'Geen' ? "empty" : "") + 
                                       (header.className ? " " + header.className : "") +
                                       (header.alignRight ? " align-right" : "")
                                    }
                                    >
                                    <span>
                                       {header.tagMap && header.tagMap[showValue] && header.tagMap[showValue].icon}
                                       {showValue}
                                    </span>
                                 </td>
                              );

                           })}

                        </tr>
                     )

                  })}

                  {!data.length &&
                     <tr>
                        <td
                           className="missing"
                           colSpan={headerKeys.length}>Er zijn geen gegevens gevonden.</td>
                     </tr>
                  }
               </tbody>
            </table>
         </div>   

         <div
            className="table-paginate"
            >

            <div className="table-paginate-buttons">
               
               <div 
                  className={"table-paginate-btn" + (page === 0 ? " disabled": "")}
                  onClick={() => setPage(0)}
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 12l12-7v14l-12-7zm-12 0l12-7v14l-12-7z"/></svg>
               </div>

               {(page < 3 || numPages <= 5) &&
                  [[...Array(Math.min(numPages, 5))].map((_, key) => {
                     return (
                        <div 
                           key={key}
                           className={"table-paginate-btn" + (key === page ? " active" : "")}
                           onClick={() => setPage(key)}
                           >
                           <span>{key + 1}</span>
                        </div>
                     )
                  })]
               }

               {(page >= 3 && numPages > 5 && page < numPages - 2) &&
                  [[...Array(5)].map((_, key) => {
                     return (
                        <div 
                           key={key}
                           className={"table-paginate-btn" + (key === 2 ? " active" : "")}
                           onClick={() => setPage(key - 2 + page)}
                           >
                           <span>{page - 2 + key + 1}</span>
                        </div>
                     )
                  })]
               }

               {(page >= 3 && numPages > 5 && page >= numPages - 2) &&
                  [[...Array(5)].map((_, key) => {
                     return (
                        <div 
                           key={key}
                           className={"table-paginate-btn" + (numPages - (4 - key) - 1 === page ? " active" : "")}
                           onClick={() => setPage(numPages - (4 - key) - 1)}
                           >
                           <span>{numPages - (4 - key)}</span>
                        </div>
                     )
                  })]
               }

               <div 
                  className={"table-paginate-btn" + (page === numPages - 1 ? " disabled": "")}
                  onClick={() => setPage(numPages - 1)}
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 19v-14l12 7-12 7zm12 0v-14l12 7-12 7z"/></svg>
               </div>

            </div>

         </div>

      </div>

   )
   
}