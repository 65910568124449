import { useEffect, useState } from 'react';

import useFetch from '../../hooks/useFetch';

import './ConsentInput.scss';

export default function ConsentInput (props) {

   const [consent, setConsent] = useState({});

   const request = {
      consent: {
          endpoint: `/api/v1/consents/${props.id}`,
          method: "GET",
      }
   }

   const [data, loading, error] = useFetch(request);

   const handleChange = (value) => {

      if (props.onChange) {
         props.onChange(value);
      }

   }

   useEffect(() => {

      if (data.consent && data.consent.data) {
         setConsent(data.consent.data);
      }

   }, [loading]);


   return (

      <div 
         className="consent-input-container"
         >

         <input id={`${consent.id}_consent`} type="checkbox" name={`${consent.id}_consent`} value="yes" onChange={(e) => handleChange(e.target.checked)} />

         <label
            htmlFor={`${consent.id}_consent`}
            >

            <div>
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
            </div>

            <p>{props.customDetails ? props.customDetails : consent.details}</p>

         </label>

      </div>

   );

}